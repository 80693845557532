import mainConfigs from '../../config'
import includeJsFile from '../../../../modules/include-js-file'
import consoleLogger from '../../../../modules/console-logger'
import { addCloseButton, reloader } from '../universal-ads-functions'
import prebidMagnite from '../../vendor/prebid-magnite'
import includeHiddenOptionsButton from '../include-hidden-options-button'
import customRulesUrls, { excludedAdUnit } from '../custom-rules-urls'
import targets from '../targets'
import responsiveRefresh from '../responsive-refresh'
import interstitial from '../interstitial'
import defineSlots from '../define-slots'
import lazyLoading from '../lazy-loading'
import amazonExecuteParallelAuctionAlongsidePrebid from '../../vendor/amazon-execute-parallel-auction'
import handlerWindowDlab from '../handler-window-dlab'
import amazonTam from '../../vendor/amazon-tam'
import enrichConfigs from '../enrich-configs'
import applyCustomCss from '../apply-custom-css'
import optionsResize from '../options-resize'
import includeGamAdBlockingRecoveryToHtml from '../include-gam-ad-blocking-recovery-to-html'
import { checkFrequencyCap, frequencyCapSlotResponseCheck } from '../check-frequency-cap'
import { createSessionId } from '../hashed-emails'
import slcSetTargets from '../slc-set-targets'
import getUserData from '../get-user-data'
import initAdsInjectAd, { triggerLazyLoadElement } from '../init-ads-inject-ad'
import initPubstack from '../../vendor/pubstack'

const initAds = ({
  viewportsConfig,
  forcedConfigs,
  adTargets,
  excludedLabels,
  onSuccess = () => {},
  onEnableServices = () => {},
  onPrebidVideoSuccess = () => {},
} = {}) => {
  const [consoleLog] = consoleLogger('AdsLog')
  const { dlabOption } = handlerWindowDlab('ads')

  const configs = forcedConfigs || dlabOption?.configs || mainConfigs()

  if (!configs) {
    return false
  }
  const configsUserAdsExcludedLabels = configs?.general?.userData?.adsExcludedLabels
  const finalExcludedLabels = excludedLabels || (configsUserAdsExcludedLabels && configsUserAdsExcludedLabels()) || []

  const _remove = require('lodash/remove')

  getUserData({ configs: configs.general.userData })

  dlabOption['config'] = enrichConfigs(configs)

  viewportsConfig = viewportsConfig || configs.viewports

  window.createSessionId = createSessionId

  configs.general.preloadedFiles.forEach((file) => {
    includeJsFile(file)
  })

  if (configs?.general?.hiddenOptions) {
    includeHiddenOptionsButton(configs.general.hiddenOptions)
  }

  if (configs.general.pubstack) {
    consoleLog('Pubstack', 'Pubstack is enabled')

    initPubstack({
      configs,
      viewportsConfig,
      adTargets,
    })
    consoleLog('Pubstack', 'Pubstack successful initialized')

    onSuccess()
    consoleLog('Pubstack', 'Done!')

    return
  }
  consoleLog('Pubstack', 'Pubstack is disabled')

  if (configs.general.adBlockingRecovery) {
    consoleLog('GAM-Adblock', 'Activating...')
    if (configs.general.adBlockingRecovery.frequencyCap) {
      const frequencyCapConfig = configs.general.adBlockingRecovery.frequencyCap
      consoleLog('GAM-Adblock', 'Check if that killjoy of Frequency Cap is holding me back...')
      const show = checkFrequencyCap(frequencyCapConfig.type, frequencyCapConfig.amount)
      if (show) {
        consoleLog('GAM-Adblock', 'Frequency Cap said yes! Start and show!')
        includeGamAdBlockingRecoveryToHtml()
      } else {
        consoleLog('GAM-Adblock', 'I got capped :-(')
      }
    } else {
      consoleLog('GAM-Adblock', 'Start and show!')
      includeGamAdBlockingRecoveryToHtml()
    }
  }
  window.googletag = window.googletag || { cmd: [] }
  window.googletag.cmd = window.googletag.cmd || []
  window.googletag.cmd.push(function () {
    let slots = configs.slots
    const allSlots = slots
    const sizeMappings = configs.sizeMappings
    const generalConfigs = configs.general
    let anchorSlot = null
    let interstitialSlot = null

    if (generalConfigs.setPageUrl) {
      consoleLog('Page URL', generalConfigs.setPageUrl)
      googletag.pubads().set('page_url', '' + generalConfigs.setPageUrl + '')
    }

    if (generalConfigs.enableSingleRequest) {
      googletag.pubads().enableSingleRequest()
    }

    googletag.pubads().collapseEmptyDivs(generalConfigs.collapseEmptyDivs || true)

    if (generalConfigs.interstitial) {
      const interstitialAd = interstitial({
        googletag,
        config: generalConfigs.interstitial,
        viewportsConfig,
      })

      interstitialAd.show()
    }

    if (generalConfigs.lazyLoading) {
      googletag.pubads().enableLazyLoad({
        fetchMarginPercent: generalConfigs.lazyLoading.fetchMarginPercent
          ? generalConfigs.lazyLoading.fetchMarginPercent
          : 500,
        renderMarginPercent: generalConfigs.lazyLoading.renderMarginPercent
          ? generalConfigs.lazyLoading.renderMarginPercent
          : 200,
        mobileScaling: generalConfigs.lazyLoading.mobileScaling ? generalConfigs.lazyLoading.mobileScaling : 2.0, // Double the above values on mobile.
      })
    }
    slcSetTargets({ googletag })
    targets({
      configs: generalConfigs.targeting,
      googletag,
      adTargets,
      testValue: configs.general.testValue,
      targetsKeyPrefix: configs.general.targetsKeyPrefix,
    })

    const showAds = customRulesUrls({
      googletag,
      urls: configs && configs.general.customRulesUrls,
      excludedLabels: finalExcludedLabels,
    })

    if (showAds === false) {
      return false
    }

    onEnableServices({ googletag: googletag })
    if (generalConfigs.disableInitialLoad) {
      googletag.pubads().disableInitialLoad()
    }

    if (generalConfigs.anchorAd) {
      let anchorAdsWanted = true
      let maxWidth = Array.isArray(generalConfigs.anchorAd.maxWidth)
        ? generalConfigs.anchorAd.maxWidth[0]
        : generalConfigs.anchorAd.maxWidth
      if (generalConfigs.anchorAd.maxWidth && window.innerWidth > maxWidth) {
        anchorAdsWanted = false
      }

      if (anchorAdsWanted) {
        const anchorPosition =
          generalConfigs.anchorAd.position === 'top'
            ? googletag.enums.OutOfPageFormat.TOP_ANCHOR
            : googletag.enums.OutOfPageFormat.BOTTOM_ANCHOR

        const anchorPath =
          location.pathname === '/'
            ? generalConfigs.anchorAd.path.replace('<LOCATION>', 'hp')
            : generalConfigs.anchorAd.path.replace('<LOCATION>', 'default')
        consoleLog('AnchorAds', 'Activated ' + anchorPath + '!')
        anchorSlot = googletag.defineOutOfPageSlot(anchorPath, anchorPosition)
        if (anchorSlot) {
          anchorSlot.addService(googletag.pubads())
        } else {
          consoleLog('AnchorAds', 'Browser does not support!')
        }
      } else {
        consoleLog('AnchorAds', 'Too Wide!')
      }
    }

    if (generalConfigs.outOfPageInterstitial) {
      let outOfPageInterstitialWanted = true
      let maxWidth = Array.isArray(generalConfigs.outOfPageInterstitial.maxWidth)
        ? generalConfigs.outOfPageInterstitial.maxWidth[0]
        : generalConfigs.outOfPageInterstitial.maxWidth
      if (generalConfigs.outOfPageInterstitial.maxWidth && window.innerWidth > maxWidth) {
        outOfPageInterstitialWanted = false
      }

      if (outOfPageInterstitialWanted) {
        consoleLog('OutOfPageIS', 'Activated!')

        interstitialSlot = googletag.defineOutOfPageSlot(
          '/6355419/Travel/Europe/France/Paris',
          googletag.enums.OutOfPageFormat.INTERSTITIAL,
        )
        if (interstitialSlot) {
          interstitialSlot.addService(googletag.pubads())
        }
      } else {
        consoleLog('OutOfPageIS', 'Too Wide!')
      }
    }

    if (generalConfigs.interstitial && generalConfigs.interstitial.frequencyCap) {
      const frequencyCapConfig = generalConfigs.interstitial.frequencyCap
      const frequencyCapOkay = checkFrequencyCap(frequencyCapConfig.type, frequencyCapConfig.amount, 'ifq', false)

      if (!frequencyCapOkay) {
        consoleLog('Interstitial Blocked by FreqCap')
        const interstitialSlot = generalConfigs.interstitial.slotId
        _remove(slots, (slot) => {
          return slot.id === interstitialSlot
        })
      }
    }

    googletag.enableServices()

    const forSlot = (slot) => {
      if (excludedAdUnit.includes(slot.id)) {
        consoleLog(`div ${slot.id} excluded because of custom rules`)
        return false
      }
      if (slot.frequencyCap) {
        frequencyCapSlotResponseCheck({ slot, googletag })
      }
      if (slot.inject) {
        return initAdsInjectAd(slot)
      }
    }

    // ToDo: only when prebid == true
    const prebid = prebidMagnite({
      googletag,
      config: generalConfigs.prebid,
      onPrebidVideoSuccess,
      pageUrl: generalConfigs.setPageUrl,
    })
    const amazonEPA = amazonExecuteParallelAuctionAlongsidePrebid({
      googletag,
      config: generalConfigs.prebid,
    })

    const forSlotSizeMapping = ({ slot, viewportSize, sizeMappings }) => {
      applyCustomCss({ slot })

      if (generalConfigs.prebid && generalConfigs.prebid.setSizeMapping) {
        prebid.getSizeMappings({ slotId: slot.id, viewportSize, sizes: sizeMappings.sizes })
      }
    }

    const slotDefiner = defineSlots({
      googletag,
      sizeMappings,
      forSlot,
      forSlotSizeMapping,
      customRules: configs && configs.general.customRulesUrls,
      disableAddScrollbarWidth: generalConfigs.disableAddScrollbarWidth,
    })
    dlabOption['prebid'] = prebid
    dlabOption['slotDefiner'] = slotDefiner

    const lazyLoadAds = lazyLoading({
      googletag,
      allSlots: allSlots,
      config: generalConfigs && generalConfigs.lazyLoad,
      slotDefiner,
      customTriggerElement: triggerLazyLoadElement,
    })
    if (generalConfigs.lazyLoad) {
      slots = lazyLoadAds.checkAdUnitVisibility({ slots })

      const onLazyLoadingRefresh = ({ newlyDefinedSlots }) => {
        slcSetTargets({ googletag })
        if (generalConfigs.prebid && !generalConfigs.amazontam) {
          consoleLog('LazyLoading Prebid', `refresh`)
          prebid.init({ slots: newlyDefinedSlots })
        }
        if (generalConfigs.amazontam) {
          consoleLog('LazyLoading AEPA - refresh', newlyDefinedSlots)
          amazonEPA.init({
            prebidInit: prebid.init,
            slots: newlyDefinedSlots,
          })
        }
        if (!generalConfigs.prebid && !generalConfigs.amazontam) {
          consoleLog('LazyLoading onRefresh - GoogleTag', `refresh`)
          googletag.pubads().refresh(newlyDefinedSlots)
        }
      }

      lazyLoadAds.init({ onRefresh: onLazyLoadingRefresh, alreadyProcessedSlots: slots })
    }

    consoleLog('Defining slots for (' + slots.length + ') slots')

    const definedSlots = slotDefiner.init(slots)
    lazyLoadAds.setProcessedSlots({ slots })

    if (generalConfigs.addCloseButton) {
      addCloseButton(generalConfigs.addCloseButton)
    }
    if (generalConfigs.reloader) {
      reloader(generalConfigs.reloader)
    }
    if (generalConfigs.prebid && !generalConfigs.amazontam) {
      prebid.init({ slots: definedSlots, onPrebidVideoSuccess })
    }

    if (generalConfigs.amazontam) {
      amazonTam()
      amazonEPA.init({
        prebidInit: prebid.init,
        slots: definedSlots,
      })
    }

    // Resize Part
    if (viewportsConfig) {
      responsiveRefresh({
        googletag,
        viewportsConfig,
        generalConfigs,
        ...optionsResize,
      })
    }

    if (anchorSlot && generalConfigs.disableInitialLoad) {
      consoleLog('AnchorAds', 'Refresh')
      consoleLog('AnchorAds', googletag.pubads().refresh([anchorSlot]))
    }
    if (interstitialSlot && generalConfigs.disableInitialLoad) {
      consoleLog('OutOfPageIS', 'Refresh')
      consoleLog('OutOfPageIS', googletag.pubads().refresh([interstitialSlot]))
    }

    onSuccess()
  })
}

export default initAds
