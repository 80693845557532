// For full documentation see
// https://github.com/eBayClassifiedsGroup/react-advertising/wiki/Configuration#size-mappings

import settingsSTOL from '../../../../topics/DlabSettings/settings'
import slcOnLoad from '../../modules/slc-on-load'
import getSplusEmail from '../../modules/get-splus-email'

const viewports = {
  desktop: [1381, 0],
  desktop_s: [1081, 0],
  tablet: [970, 0],
  tablet_s: [862, 0],
  mobile_l: [791, 0],
  mobile: [768, 0],
  mobile_m: [480, 0],
  mobile_s: [320, 0],
  all: [0, 0],
}

// Config for Südtirol News
const stolConfig = {
  // GPT size mapping definitions (object). Ideally there is a sizeMapping for each Slot
  viewports: viewports,

  general: {
    pubstack: true,
    userData: {
      email: getSplusEmail,
    },
    preloadedFiles: [
      {
        link: 'https://connect.suedtirol.live/slc.js',
        async: true,
        onload: () => {
          slcOnLoad({
            id: 'SLC-8GCSJXZB',
            enableTargets: true,
            prefixUserId: 'STOL',
          })
          window.addEventListener('message', function (event) {
            if (!event.origin.endsWith('.pinpoll.com') || !event.data || event.data.event != 'vote') {
              return
            }
            console.log('dbg_DT')
            if (event.data && event.data.audiences) {
              console.log('dbg_DT_A')
              const interestIds = event.data.audiences.interests.map((interest) => interest.id)
              if (interestIds.length > 0) {
                console.log('dbg_DT_A_SND')
                SLC('pageEvent', 'pinpoll', 'vote', '', '', JSON.stringify(interestIds))
              }
            }
          })
        },
      },
      {
        link: 'https://launchpad-wrapper.privacymanager.io/8635aa92-be83-46e4-945a-975b0f76a243/launchpad-liveramp.js',
        async: true,
      },
      {
        link: 'https://boot.pbstck.com/v1/adm/3a80996f-3c63-459d-8eb0-7f742266bd02',
        async: true,
        onload: () => {
          console.log('Pubstack done')
        },
        idLoadOnce: 'pubstack-script',
      },
    ],
    hiddenOptions: settingsSTOL(),
    // interstitial: {
    //   containerId: 'dlab-interstitial',
    //   slotId: 'dlab-ad-IS-web',
    //   slotPath: '/2273514/stol7_web_is',
    //   displayTimeInSec: 15,
    // },
  },
}

export default stolConfig
